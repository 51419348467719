import { carregaScriptChat, removeScriptChat, showScriptChat } from '@/shared/plugins/chat'
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState('planos', ['empresaPlano']),
    },
    watch: {
        empresaPlano(newValue) {
            if (newValue && !this.scriptCarregado) {
                carregaScriptChat();
                this.scriptCarregado = true;                
                if (this.removeChat) {
                    // Aguarda o botão estar disponível antes de ocultar
                    this.aguardaBotaoChat(() => {
                        removeScriptChat();
                    });
                }
            }
        },
        // Observa mudanças na rota para atualizar o estado do chat
        '$route'(to) {
            this.atualizarRemoveChat(to);
            this.controlarChat();
        }
    },
    data() {
        return {
            scriptCarregado: false,
            removeChat: false
        }
    },
    created() {
        // Define o estado inicial com base na URL atual
        this.atualizarRemoveChat(this.$route || { path: window.location.pathname });
    },
    mounted() {
        let corPrimaria = this.$app.cor_primaria
        let corContabilidade = this.$app.cor_contabilidade
        let corIntranet = this.$app.cor_intranet
        let corParceiro = this.$app.cor_contabilidade
        if(
            this.$url.module == 'contabilidade' 
            &&
            corContabilidade    
        ){
            corPrimaria = corContabilidade
        }
        if(
            this.$url.module == 'parceiro' 
            &&
            corParceiro
        ){
            corPrimaria = corParceiro
        }
        if(
            this.$url.module == 'intranet' 
            &&
            corIntranet
        ){
            corPrimaria = corIntranet
        }
        this.aplicarCoresCss(corPrimaria, this.$app.cor_secundaria)        
    },
    methods: {
        // Atualiza o estado do chat com base na rota atual
        atualizarRemoveChat(route) {
            this.removeChat = route.path.includes('planos_v2') || 
                            route.path.includes('redefinir_senha');
        },
        // controla a exibição do chat
        controlarChat() {            
            if (this.scriptCarregado) {
                if (this.removeChat) {
                    removeScriptChat();
                } else {
                    showScriptChat();
                }
            }
        },
        aguardaBotaoChat(callback) {
            const verificaBotao = () => {
                const botaoSuporte = document.getElementById('botao_suporte_zendesk');
                if (botaoSuporte) {
                    callback();
                } else {
                    // Tenta novamente após um pequeno intervalo
                    setTimeout(verificaBotao, 100);
                }
            };
            this.$nextTick(verificaBotao); // Começa a verificação após o próximo ciclo de atualização do DOM
        },
        aplicarCoresCss(corPrimaria, corSecundaria){
            const estilos = [
                {
                    elemento: '#bem-vindo .modal-header, #curtir-facebook .modal-header',
                    propriedades: [
                        'background: #' + corPrimaria,
                        'color: #FFFFFF'
                    ]
                },
                {
                    elemento: '.pace .pace-progress',
                    propriedades: [
                        'background: #' + corSecundaria,
                    ]
                },
                {
                    elemento: 'a',
                    propriedades: [
                        'color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: 'a:hover, a:active, a:focus',
                    propriedades: [
                        'color: #' + corSecundaria,
                    ]
                },
                {
                    elemento: '.text-primary',
                    propriedades: [
                        'color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.text-primary:hover',
                    propriedades: [
                        'color: #' + corSecundaria,
                    ]
                },
                {
                    elemento: '.cor-padrao',
                    propriedades: [
                        'color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.flash-tab',
                    propriedades: [
                       'background: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.dropdown-menu > li > a:hover, .dropdown-menu > li > ul > li > .dropdown-header:hover, .dropdown-menu > li > ul > li > a:hover',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.navbar-nav > .user-menu > .dropdown-menu > li.user-header',
                    propriedades: [
                        'background: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.box.box-primary',
                    propriedades: [
                        'border-top-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.box.box-solid.box-primary > .box-header',
                    propriedades: [
                        'background: #' + corPrimaria,
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.box .todo-list > li.primary',
                    propriedades: [
                        'border-left-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.card-header.bg-primary',
                    propriedades: [
                        'background: #' + corPrimaria + ' !important',
                        'background-color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.bg-light-blue',
                    propriedades: [
                        'background-color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.bg-olive',
                    propriedades: [
                        'background-color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.text-light-blue',
                    propriedades: [
                        'color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.text-olive',
                    propriedades: [
                        'color: #' + corPrimaria  + ' !important',
                    ]
                },
                {
                    elemento: '.btn.btn-primary',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                        'border-color: #' + corSecundaria,
                    ]
                },
                {
                    elemento: '.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary',
                    propriedades: [
                        'background-color: #' + corSecundaria,
                        'border-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.badge.badge-primary',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.nav.nav-pills > li.active > a, .nav.nav-pills > li.active > a:hover',
                    propriedades: [
                        'border-top-color: #' + corPrimaria,
                        'color: #444',
                    ]
                },
                {
                    elemento: '.nav.nav-pills.nav-stacked > li.active > a, .nav.nav-pills.nav-stacked > li.active > a:hover',
                    propriedades: [
                        'border-left-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.nav-tabs-custom > .nav-tabs > li.active',
                    propriedades: [
                        'border-top-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.nav-pills .nav-link.active, .nav-pills .show>.nav-link',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.form-box .header',
                    propriedades: [
                        'background: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.skin-blue .navbar',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.form-control:hover,.form-control:focus,.form-control-table:hover,.form-control-table:focus',
                    propriedades: [
                        'border-color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.custom-control-input:checked~.custom-control-label::before',
                    propriedades: [
                        'border-color: #' + corPrimaria,
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before',
                    propriedades: [
                        'border-color: #' + corPrimaria,
                        'background-color: #' + corPrimaria,
                        'opacity: .65',
                    ]
                },
                {
                    elemento: '.progress-bar-light-blue, .progress-bar-primary',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: 'fieldset legend',
                    propriedades: [
                        'color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: 'table.calendario tr td .compromissos',
                    propriedades: [
                        'background: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.btn.btn-primary',
                    propriedades: [
                        'border-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.btn.btn-primary:hover, .btn.btn-primary:active, .btn.btn-primary.hover',
                    propriedades: [
                        'background-color: #' + corPrimaria + ' !important',
                        'border-color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, .dropdown-item.active, .dropdown-item:active',
                    propriedades: [
                        'background-color: #' + corPrimaria + ' !important',
                        'border-color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.skin-blue .logo',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: 'div.token-input-dropdown ul li.token-input-selected-dropdown-item',
                    propriedades: [
                        'background: #' + corPrimaria + '!important',
                    ]
                },
                {
                    elemento: '.text-info',
                    propriedades: [
                        'color:#' + corPrimaria,
                    ]
                },
                {
                    elemento: '.text-info:hover',
                    propriedades: [
                        'color:#' + corSecundaria,
                    ]
                },
                {
                    elemento: '.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.wizard li.active span.round-tab',
                    propriedades: [
                        'border: 2px solid #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.wizard li.active span.round-tab i',
                    propriedades: [
                        'color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.wizard li:after',
                    propriedades: [
                        'border-bottom-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.wizard li.active:after',
                    propriedades: [
                        'border-bottom-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.ui-button.ui-state-active:hover',
                    propriedades: [
                        'background: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.autocomplete ul.results li:hover',
                    propriedades: [
                        'background: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.autocomplete ul.results li.active',
                    propriedades: [
                        'background: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.page-item.active .page-link',
                    propriedades: [
                        'background-color: #' + corPrimaria + ' !important',
                        'border-color: #' + corPrimaria + ' !important',
                        'color: #FFFFFF !important',
                    ]
                },
                {
                    elemento: '.page-link',
                    propriedades: [
                        'color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.fc-button-primary',
                    propriedades: [
                        'background-color: #' + corPrimaria + ' !important',
                        'border-color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.fc-button-active',
                    propriedades: [
                        'background-color: #' + corSecundaria + ' !important',
                        'border-color: #' + corSecundaria + ' !important',
                    ]
                },
                {
                    elemento: '.fc-event',
                    propriedades: [
                        'background-color: #' + corSecundaria + ' !important',
                        'border-color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.atwho-cur',
                    propriedades: [
                        `background-color: #${corPrimaria} !important`,
                        'color: white'
                    ]
                },
                {
                    elemento: '.interacoes .mencionado',
                    propriedades: [
                        `background-color: #${corPrimaria} !important`,
                        'border-radius: 15px',
                        'color: #fff',
                        'padding: 0px 4px 3px 4px'
                    ]
                },
                // barra de rolagem
                    /* Firefox */
                    {
                        elemento: '*',
                        propriedades: [
                            `scrollbar-color: #${corPrimaria} #ececec`
                        ]
                    },
                    /* Chrome, Edge, Safari */
                    {
                        elemento: '*::-webkit-scrollbar',
                        propriedades: [
                            'width: 15px',
                            'height: 15px'
                        ]
                    },
                    {
                        elemento: '*::-webkit-scrollbar-track',
                        propriedades: ['background: #ececec']
                    },
                    {
                        elemento: '*::-webkit-scrollbar-thumb',
                        propriedades: [
                            `background-color: #${corPrimaria}`,
                            'border-radius: 20px',
                            'border: 3px solid #ececec'
                        ]
                    }
                // barra de rolagem
            ]
            estilos.forEach((estilo) => {
                estilo.propriedades.forEach((propriedade) => {
                    let elemento = document.createElement('style')
                    elemento.type = 'text/css'
                    document.getElementsByTagName('head')[0].appendChild(elemento)
                    if(!(elemento.sheet || {}).insertRule){
                        (elemento.styleSheet || elemento.sheet).addRule(estilo.elemento, propriedade)
                    }else{
                        elemento.sheet.insertRule(estilo.elemento + "{" + propriedade + "}",0)
                    }
                })
            })
        }
    },
}