<template>
    <li class="nav-link d-flex align-items-center">
        <div class="div-photo" v-b-toggle.sidebar-right>
            <div v-if="possuiFoto" class="user-photo">
                <b-img :src="usuarioLogado['caminho_foto']" rounded="circle" :alt="usuarioLogado['nome']" />
            </div>
            <div v-else class="user-photo user-photo-initials">
                {{ pegaIniciais(usuarioLogado['nome']) }}
            </div>
        </div>
        <b-sidebar 
            id="sidebar-right" 
            title="Sidebar" 
            width="270px"
            right 
            shadow 
            backdrop 
            no-header 
            @hidden="fecharSidebar"
            @shown="abrirSidebar"
        >
            <template #default="{ hide }">
                <div class="btn-close" @click="hide">
                    <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="x" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-x b-icon bi"><g><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></g></svg>
                </div>
                <b-dropdown-header class="pt-3">
                    <div class="text-center">
                        <b-img :src="usuarioLogado['caminho_foto']" rounded="circle" class="user-profile-image mb-2" />
                        <p class="mb-0"><strong>{{ usuarioLogado['nome'] }}</strong></p>
                        <div>
                            <small>{{ usuarioLogado['email'] }}</small>
                        </div>
                        <b-button
                            variant="default"
                            class="text-primary mt-2 w-100"
                            size="sm"
                            :href="$urlBase + '/usuarios/meus_dados'" 
                        >
                            <i class="fa ml-2 fa-user"/> Meus dados 
                        </b-button>
                    </div>
                </b-dropdown-header>
                <template
                    v-if="opcoesUsuarioFiltradas.length > 0"
                >
                    <b-dropdown-divider />
                    <li class="py-1">
                        <span class="d-flex justify-content-between">
                            <div class="pl-4 d-flex align-items-center">
                                <span class="menu-header-sidebar">Configurações</span>
                            </div>
                        </span>
                    </li>
                    <li v-for="(opcao, i) in opcoesUsuarioFiltradas" 
                        :key="'usuario-' + i" 
                        class="py-1 sub-ajuda-li"
                    >
                        <a
                            :href="opcao.rota" 
                            class="dropdown-item ajuda-item"
                        >
                            <div class="d-flex align-items-center">
                                <i :class="opcao.icone" class="mr-1 tamanho-icone"></i>
                                <span class="submenu-fonte">{{ opcao.nome }}</span>
                            </div>
                        </a>
                    </li>
                </template>
                <template 
                    v-if="
                        opcoesSubAjuda.length > 0
                        &&
                        $usuarioLogado['Empresa']['modalidade'] != 'NFE'
                    "
                >
                    <b-dropdown-divider />
                    <li class="py-1">
                        <span class="d-flex justify-content-between">
                            <div class="pl-4 d-flex align-items-center">
                                <span class="menu-header-sidebar">Ajuda</span>
                            </div>
                        </span>
                    </li>
                    <div>
                        <li 
                            v-for="(opcao, i) in opcoesSubAjuda" 
                            :key="'subAjuda-' + i" 
                            class="py-1 sub-ajuda-li"
                        >
                            <a
                                :href="opcao.rota"  
                                class="dropdown-item ajuda-item"
                            >
                                <div class="d-flex align-items-center">
                                    <i :class="opcao.icone" class="mr-1 tamanho-icone"></i>
                                    <span class="submenu-fonte">{{ opcao.nome }}</span>
                                </div>
                            </a>
                        </li>
                    </div>
                </template>
                <div v-if="$usuarioLogado['Empresa']['modalidade'] == 'NFE'">
                    <li class="py-1 sub-ajuda-li" @click="baixar(usuarioLogado['suporte_remoto'])">
                        <a class="dropdown-item ajuda-item">
                            <div class="d-flex align-items-center">
                                <i class="fa icon-sync mr-1 tamanho-icone"></i>
                                <span>Suporte remoto</span>
                            </div>
                        </a>
                    </li>
                </div>
                <template 
                    v-if="
                        opcoesSistemaFiltradas.length > 0
                        &&
                        (
                            $usuarioLogado['Empresa']['tipo_cobranca'] == 'P' 
                            || 
                            $usuarioLogado['Empresa']['situacao'] == '0'
                        )
                    "
                >
                    <b-dropdown-divider />
                    <li class="py-1">
                        <span class="d-flex justify-content-between">
                            <div class="pl-4 d-flex align-items-center">
                                <span class="menu-header-sidebar">Sistema</span>
                            </div>
                        </span>
                    </li>
                    <li v-for="(opcao, i) in opcoesSistemaFiltradas" 
                        :key="'opcao-sistema-' + i" 
                        class="py-1 sub-ajuda-li"
                    >
                        <a
                            :href="opcao.rota" 
                            class="dropdown-item ajuda-item"
                        >
                            <div class="d-flex align-items-center">
                                <i :class="opcao.icone" class="mr-1 tamanho-icone"></i>
                                <span class="submenu-fonte">{{ opcao.nome }}</span>
                            </div>
                        </a>
                    </li>
                </template>
                <div class="p-3">
                    <b-button v-if="$route.meta.botaoSairPdv" class="d-block w-100" variant="primary" style="color: #efefef;"
                        :href="`${$urlBase}/vendas_balcao`">
                        <span>Ir para ERP</span>
                    </b-button>
                    <b-button class="d-flex w-100" :class="$route.meta.botaoSairPdv ? 'mt-2' : ''"
                        :variant="$route.meta.botaoSairPdv ? 'default' : 'primary'" @click="logout">
                        <i class="fa fa-sign-out mr-1"></i>
                        <span>Sair</span>
                    </b-button>
                </div>
            </template>
        </b-sidebar>
    </li>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { download } from '@/shared/common/functions/retornos'

export default {
    name: 'UsuarioTopo',
    computed: {
        ...mapState("usuarios", ["usuarioLogado"]),
        possuiFoto() {
            return !this.usuarioLogado["caminho_foto"].includes("sem-foto.png");
        },
        opcoesUsuarioFiltradas() {
            return this.opcoesUsuario.filter((opcao) => opcao.exibir == true)
        },
        opcoesSistemaFiltradas() {
            return this.opcoesSistema.filter((opcao) => opcao.exibir == true)
        }
    },
    data() {
        return {
            opcoesUsuario: [
                { nome: 'Gerais', icone: 'fa fa-cog', rota: "/configuracoes/gerais", exibir: true },
                { nome: 'Meu plano', icone: 'fa fa-star-half-o', rota: this.$urlBase + "/planos/meu_plano", exibir: this.$usuarioLogado['Empresa']['tipo_cobranca'] == 'E' },
                { nome: 'Meu contador', icone: 'fa fa-suitcase', rota: '/contador', exibir: this.$usuarioLogado['Empresa']['modalidade'] != 'NFE' },
                { nome: 'Domínio próprio', icone: 'fa fa-globe', rota: "/dominios/configurar", exibir: this.$usuarioLogado['Empresa']['modalidade'] != 'NFE' },
            ],
            opcoesSubAjuda: [
                { nome: 'Central de ajuda', icone: 'fa fa-list-alt', rota: '/ajuda' },
                { nome: 'Portal de ideias', icone: 'fa fa-lightbulb-o', rota: '/ideias' },
                { nome: 'Atualizações', icone: 'fa fa-refresh', rota: '/atualizacoes' },
            ],
            opcoesSistema: [
                { nome: 'Backup de dados', icone: 'fa fa-hdd-o', rota: '/backup_dados', exibir: this.$usuarioLogado['Empresa']['modalidade'] != 'NFE'},
                { nome: 'Exclusão de dados', icone: 'fa fa-trash-o', rota: '/exclusao_dados', exibir: true},
            ],
            exibirMenuAjuda: false,
            exibirMenuUsuario: true,
            alturaScroll: 0
        }
    },
    mounted() {
        document.documentElement.style.setProperty('--cor-primaria', `#${this.$app.cor_primaria}`);
        const backdrop = document.querySelector('.b-sidebar-backdrop');
        if (backdrop) {
            backdrop.addEventListener('wheel', this.onBackdrop);
            backdrop.addEventListener('touchmove', this.onBackdrop, { passive: false });
        }
    },
    beforeDestroy() {
        const backdrop = document.querySelector('.b-sidebar-backdrop');
        if (backdrop) {
            backdrop.removeEventListener('wheel', this.onBackdrop);
            backdrop.removeEventListener('touchmove', this.onBackdrop);
        }
    },
    methods: {
        ...mapActions("usuarios", {
            xLogout: "logout",
        }),
        onBackdrop(event) {
            event.preventDefault();
            const body = document.scrollingElement || document.body;
            let scrollDelta = 0;
            if (event.type === 'wheel') {
                scrollDelta = event.deltaY * 2;
            } else if (event.type === 'touchmove') {
                const touch = event.touches[0];
                if (this.lastTouch) {
                    scrollDelta = (this.lastTouch - touch.clientY) * 2;
                }
                this.lastTouch = touch.clientY;
            }
            body.scrollTop += scrollDelta;
            this.alturaScroll = body.scrollTop
            console.log(this.alturaScroll)
        },
        async logout() {
            this.$loading.show()
            const logout = await this.xLogout();
            if (logout.status === "success") {
                location.href = this.$urlBase + "/login";
            } else {
                this.$loading.hide()
                this.$dialog.error(logout.message);
            }
        },
        pegaIniciais(nome) {
            const iniciais = nome
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase())
                .join("");
            return iniciais.length > 2 ? iniciais.slice(0, 2) : iniciais;
        },
        baixar(url) {
            download(url)
            this.$notify.success('Download iniciado com sucesso!')
        },
        abrirSidebar() {
            const body = document.scrollingElement || document.body;
            this.alturaScroll = body.scrollTop
            const html = document.documentElement;
            html.style.overflow = "hidden";
            html.style.scrollbarWidth = "none";
            html.style.setProperty("--webkit-scrollbar-width", "0");
        },
        fecharSidebar() {
            const body = document.scrollingElement || document.body;
            body.scrollTop = this.alturaScroll
            const html = document.documentElement;
            html.style.overflow = "";
            html.style.scrollbarWidth = "";
            html.style.setProperty("--webkit-scrollbar-width", "");
        },
    },
};
</script>

<style scoped>
.dropdown-trigger {
    cursor: pointer;
}

.div-photo {
    height: 11px;
    margin-right: 10px;
}

.user-photo {
    width: 30px;
    height: 30px;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5px;
}

.user-photo-initials {
    border-radius: 50%;
    background-color: #efefef;
    color: #444444;
}

.user-profile-image {
    width: 60px;
    height: 60px;
}

/* ::v-deep .dropdown-item:hover {
    color: #efefef !important;
} */

/* ::v-deep .dropdown-menu {
    border-radius: 8px !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    width: 280px !important;
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
} */

@media (max-width: 424px) {
    ::v-deep .dropdown-menu {
        width: 200px !important;
    }
}

::v-deep .dropdown-menu::-webkit-scrollbar {
    width: 5px;
}

::v-deep .dropdown-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::v-deep .dropdown-menu::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

::v-deep .dropdown-menu::-webkit-scrollbar-thumb:hover {
    background: #555;
}


::v-deep .btn-footer-dropdown {
    border: none;
    border-radius: 0;
}

.skin-blue .navbar .nav a {
    color: unset;
}

::v-deep .ajuda-item {
    cursor: pointer;
    color: #444444;
}

::v-deep .ajuda-item:hover {
    color: #efefef !important;
    background-color: var(--cor-primaria)
}

.tamanho-icone {
    width: 18px !important;
}

.sub-ajuda-li {
    color: #444444;
}

.submenu-fonte {
    font-size: 15px;
}

.menu-header-sidebar {
    color: #6c757d;
    font-size: 12px;
}
.btn-close{
    padding: 5px 10px;
    float: left;
    color: #444444;
    cursor: pointer;
}
.atalho-sidebar a{
    padding: 0 15px 15px 15px;
    cursor: pointer;
}
a.btn:hover{
    color: unset !important;
}
</style>
<style>
#sidebar-right~.b-sidebar-backdrop {
    opacity: 0;
    touch-action: none;
}

@media (max-width: 768px) {
    .b-sidebar {
        width: 280px !important;
    }
}
</style>