import Vue from 'vue'
import { baseURL} from '@/shared/services/config'
export const carregaScriptChat = (() => {
    if(Vue.prototype.$app.script_chat){
        const s1 = document.createElement('script')
        const s0 = document.getElementsByTagName('script')[0]
        s1.async = true
        s1.src='/js/chat/' + Vue.prototype.$app.script_chat + '.js'
        s1.charset='UTF-8'
        try {  
            s1.setAttribute('cor_primaria', Vue.prototype.$app.cor_primaria)
            s1.setAttribute('cor_secundaria', Vue.prototype.$app.cor_secundaria)
            s1.setAttribute('cor_intranet', Vue.prototype.$app.cor_intranet)
            s1.setAttribute('cor_contabilidade', Vue.prototype.$app.cor_contabilidade)
            s1.setAttribute('url_api', baseURL)
            s1.setAttribute('url_base', Vue.prototype.$urlBase)
            s1.setAttribute('nome_usuario', Vue.prototype.$usuarioLogado['nome'] || '')
            s1.setAttribute('email_usuario', Vue.prototype.$usuarioLogado['email'] || '')
            s1.setAttribute('nome_loja', Vue.prototype.$usuarioLogado['Loja']['nome'] || '')
            s1.setAttribute('empresa_id', Vue.prototype.$usuarioLogado['Empresa']['id'] || '')
            s1.setAttribute('nome_dominio', Vue.prototype.$usuarioLogado['Dominio']['nome'] || '')
            s1.setAttribute('nome_plano', Vue.prototype.$empresaPlano['nome_plano'] || '')
            s1.setAttribute('duracao_plano', Vue.prototype.$empresaPlano['duracao'] || '')
        } catch (error) {
            error
        }
        s1.setAttribute('crossorigin','*')
        s0.parentNode.insertBefore(s1, s0)
    }
})

export const removeScriptChat = () => {
    const botaoSuporte = document.getElementById('botao_suporte_zendesk');
    if (botaoSuporte) {
        botaoSuporte.style.display = 'none'; 
    }
}

export const showScriptChat = () => { 
    const botaoSuporte = document.getElementById('botao_suporte_zendesk');
    if (botaoSuporte) {
        botaoSuporte.style.display = 'block'; 
    }
}