import Vue from 'vue'

export const download = ((link, nome) => {
    const download = document.createElement('a')
    const fileName = nome
    download.href = link
    download.download = fileName
    download.click()    
})

export const downloadBase64 = ((arquivo, nome, extensao) => {
    let type
    if(['xlsx', 'xls'].includes(extensao)){
        type = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }else{
        type = extensao
    }
    const link = 'data:application/' + type + ';base64,' + arquivo
    const download = document.createElement('a')
    const fileName = nome
    download.href = link
    download.download = fileName
    download.click()    
})

export const validaUrlRedirecionamento = ((url) => {
    if(typeof url == 'object'){
        const obj = Object.assign(
            Vue.prototype.$url,
            url
        )
        url = Vue.prototype.$urlBase 
        + '/' + obj.controller
        + '/' + obj.action
    }
    return url
})
