<template >
    <b-modal
        :id="id"
        :size="dSize"
        ref="modalFrame" 
        :hide-header="true"
        :hide-footer="true"
        content-class="modal-frame"
        @hide="hide()"
        :no-close-on-backdrop="!closeBackdrop"
    >
        <template #default="{ hide }">
            <button 
                type="button" 
                aria-label="Close" 
                class="close"
                @click="hide()"
            >×</button>
            <component 
                v-if="component"
                :is="component"
                :modal="true"
                :params="dParams"
                @rSalvarDados="hide($event)"
            ></component>
        </template>
    </b-modal>
</template>
<script>
import Vue from 'vue'
export default {
    props: {
        size: {
            default: 'md'
        },
        params: {
            default: null
        },
        id: {
            default: ''
        },
    },
    provide: function () {
        return {
            isModal: true
        }
    },
    data(){
        return {
            component: null,
            dSize: this.size,
            dParams: this.params,
            resolve: undefined,
            reject: undefined,
            closeBackdrop: false
        }
    },
    mounted() {
        Vue.prototype.$modal = {}
        Vue.prototype.$modal['show'] = this.show
        Vue.prototype.$modal['hide'] = this.hide
    },
    methods: {
        show(component, size='md', params={}) {
            this.dSize = size
            this.dParams = params
            this.component = component
            if (this.dParams.closeBackdrop) {
                this.closeBackdrop = this.dParams.closeBackdrop;
            }
            this.$refs.modalFrame.show()
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        hide(e = null) {
            this.component = null
            if (/function/i.test(this.resolve)) {
                this.resolve(e)
            }
            this.$refs.modalFrame.hide()
        },
    }
}
</script>
<style>
    .modal-frame .content-header h1{
        font-size: 24px;
    }
    .modal-frame .breadcrumb{
        display: none;
    }
    .modal-frame .col-xs-12{
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .modal-frame section.content{
        background: none !important;
        padding: 10px 0 !important;
        margin-bottom: 0 !important;
    }
</style>