import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

// Definindo o reCAPTCHA v3
Vue.use(VueReCaptcha, { 
    siteKey: '6LdWTqEUAAAAAHnoZ7piI2iOIcyydx4a3nuKd0eg',
    loaderOptions: {
        autoHideBadge: true
    }
})

// Definindo a chave do reCAPTCHA v2
Vue.prototype.$recaptchaV2Key = '6LdubtoqAAAAAFddGIylO-sHIrpOr0Wc1IKNGkLU'