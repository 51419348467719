const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarPagamentosMovimentacoesFinanceiras',
                path: '/movimentacoes_financeiras/index_pagamento',
                component: () => import('../views/ListarPagamentos'),
            },
            {
                name: 'adicionarPagamentoMovimentacaoFinanceira',
                path: '/movimentacoes_financeiras/adicionar_pagamento',
                alias: ['/movimentacoes_financeiras/adicionar_pagamento/:id'],
                component: () => import('../views/AdicionarPagamento'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarPagamentoMovimentacaoFinanceira',
                path: '/movimentacoes_financeiras/editar_pagamento/:id',
                component: () => import('../views/EditarPagamento'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarPagamentoMovimentacaoFinanceira',
                path: '/movimentacoes_financeiras/visualizar_pagamento/:id',
                component: () => import('../views/VisualizarPagamento')
            },
            {
                name: 'listarContasFixasMovimentacoesFinanceiras',
                path: '/movimentacoes_financeiras/index_conta_fixa',
                component: () => import('../views/ListarContasFixas'),
            },
            {
                name: 'adicionarContaFixaMovimentacaoFinanceira',
                path: '/movimentacoes_financeiras/adicionar_conta_fixa',
                alias: ['/movimentacoes_financeiras/adicionar_conta_fixa/:id'],
                component: () => import('../views/AdicionarContaFixa'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarContaFixaMovimentacaoFinanceira',
                path: '/movimentacoes_financeiras/editar_conta_fixa/:id',
                component: () => import('../views/EditarContaFixa'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarContaFixaMovimentacaoFinanceira',
                path: '/movimentacoes_financeiras/visualizar_conta_fixa/:id',
                component: () => import('../views/VisualizarContaFixa')
            },
            {
                name: 'listarRecebimentosMovimentacoesFinanceiras',
                path: '/movimentacoes_financeiras/index_recebimento',
                alias: [
                    '/movimentacoes_financeiras',
                    '/movimentacoes_financeiras/index'
                ],
                component: () => import('../views/ListarRecebimentos'),
            },
            {
                name: 'adicionarRecebimentoMovimentacaoFinanceira',
                path: '/movimentacoes_financeiras/adicionar_recebimento',
                alias: ['/movimentacoes_financeiras/adicionar_recebimento/:id'],
                component: () => import('../views/AdicionarRecebimento'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarRecebimentoMovimentacaoFinanceira',
                path: '/movimentacoes_financeiras/editar_recebimento/:id',
                component: () => import('../views/EditarRecebimento'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarRecebimentoMovimentacaoFinanceira',
                path: '/movimentacoes_financeiras/visualizar_recebimento/:id',
                component: () => import('../views/VisualizarRecebimento')
            },
            {
                name: 'fluxoCaixaMovimentacaoFinanceira',
                path: '/movimentacoes_financeiras/fluxo_caixa',
                component: () => import('../views/FluxoCaixa'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'boletosBancariosMovimentacoesFinanceiras',
                path: '/movimentacoes_financeiras/boletos_bancarios',
                component: () => import('../views/BoletosBancarios'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'importarExtratoConciliacaoBancaria',
                path: '/movimentacoes_financeiras/importar_extrato',
                component: () => import('../views/ImportarExtrato')
            },
            {
                name: 'importarCadastrosMovimentacaoFinanceira',
                path: '/movimentacoes_financeiras/importar_cadastros',
                component: () => import('../views/ImportarCadastros')
            },
            {
                name: 'importarRetorno',
                path: '/movimentacoes_financeiras/importar_retorno',
                component: () => import('../views/ImportarRetorno')
            },
            {
                name: 'agruparPagamentoMovimentacaoFinanceira',
                path: '/movimentacoes_financeiras/agrupar_pagamento',
                component: () => import('../views/AgruparPagamento'),
                meta: { fullScreen: true }
            },
            {
                name: 'agruparRecebimentoMovimentacaoFinanceira',
                path: '/movimentacoes_financeiras/agrupar_recebimento',
                component: () => import('../views/AgruparRecebimento'),
                meta: { fullScreen: true }
            },
        ],
    },
    {
        name: 'linkReciboMovimentacaoFinanceira',
        path: '/recibo/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'linkFaturaMovimentacaoFinanceira',
        path: '/fatura/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'linkBoletoMovimentacaoFinanceira',
        path: '/boleto/:hash',
        component: () => import('../components/ImpressaoBoleto'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'linkPixPagamentoMovimentacaoFinanceira',
        path: '/pix/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'linkPagagamentoMovimentacaoFinanceira',
        path: '/pag/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'imprimirBoletosMovimentacaoFinanceira',
        path: '/movimentacoes_financeiras/imprimir_boletos',
        component: () => import('../components/ImpressaoBoletos'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
]

export default routes
