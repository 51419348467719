<template>
    <b-card
        no-body
        no-footer
        class="mb-3"
    >
        <!-- Cabeçalho do card -->
        <div 
            class="card-header d-flex justify-content-between align-items-center cursor-pointer"
            :aria-controls="'collapse-' + id"
            aria-expanded="isVisible"
            @click="toggleVisibility"
        >
            <!-- Slot para o título personalizado -->
            <slot name="header">
                <h5 class="mb-0">
                    {{ title }}
                </h5>
            </slot>
            <!-- Ícone de collapse -->
            <i 
                :class="isVisible ? 'fa fa-chevron-left' : 'fa fa-chevron-down'" 
                style="font-size: 10px !important;"
            />
        </div>

        <!-- Conteúdo colapsável -->
        <b-collapse
            :id="'collapse-' + id"
            v-model="isVisible"
        >
            <b-card-body class="p-3">
                <slot/>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            id: Math.random().toString(36).substr(2, 9),
            isVisible: false, // Estado local de visibilidade
        };
    },
    mounted() {
        // Inicializa a visibilidade do componente com base na prop "visible"
        this.isVisible = JSON.parse(JSON.stringify(this.visible))

    },
    methods: {
        toggleVisibility() {
            // Alterna a visibilidade e emite o evento para sincronização, se necessário
            this.isVisible = !this.isVisible;
        }
    },
};
</script>
