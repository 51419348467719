import { Validator } from 'vee-validate';
import { validaCep } from '@/shared/common/functions/numero'
import axios from 'axios';

Validator.extend('cep', {
   getMessage: 'Digite um CEP válido',
   validate: ((cep) => {
       return validaCep(cep)
   })
});

Validator.extend('pertenceEstado', {
  getMessage: (field, params)  => `O CEP informado não pertence a UF (${params[0]}) selecionada.`,
  validate: async (value, params) => {
      const baseURL = process.env.VUE_APP_API_URL;
      const ret = await axios.get(`${baseURL}/cidades/busca_cep/${value}`)
      if(ret.data.data.estado == params[0]) return true
      return false
   }
});