<template>
    <div id="app">
        <Dialog/>
        <Notify/>
        <Modal/>
        <router-view 
            v-if="carregado"
        />
    </div>
</template>
<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import Dialog from '@/shared/components/ui/Dialog'
import Notify from '@/shared/components/ui/Notify'
import Modal from '@/shared/components/ui/Modal'
import { pegaMensagem } from '@/shared/common/functions/mensagens'
import { buscaNovidades } from '@/shared/plugins/novidades'
export default {
    components: {
        Dialog, Notify, Modal
    },
    mounted(){
        this.carregaDominio()
        this.inicializaTokenDispositivo()
        pegaMensagem()
        buscaNovidades()
    },
    computed: {
        ...mapState('planos', ['empresaPlano']),
        ...mapState('dominios', ['dominio']),
        ...mapState('usuarios', ['usuarioLogado', 'tokenDispositivo']),
        carregado(){
            if(this.dominio){
                this.atualizaIcone(this.dominio)
                this.atualizaManifest(this.dominio)
            }
            return this.dominio
        }
    },
    watch:{
        dominio(dominio){
            Vue.prototype.$app = dominio
        },
        usuarioLogado(usuarioLogado){
            Vue.prototype.$usuarioLogado = usuarioLogado
        },
        tokenDispositivo: {
            handler(newTokenDispositivo) {
                Vue.prototype.$tokenDispositivo = newTokenDispositivo;
            },
            immediate: true,
        },
        empresaPlano(empresaPlano){
            Vue.prototype.$empresaPlano = empresaPlano
        },
    },
    methods: {
        ...mapActions('dominios', ['carregaDominio']),
        ...mapActions('usuarios', ['inicializaTokenDispositivo']),
        atualizaIcone(dados){
            document.querySelector('link[rel="icon"]').href = dados.caminho_favicon || ''
        },
        atualizaManifest(dados){
            let manifest = {
                'name': dados.nome,
                'short_name':  dados.nome,
                'theme_color': '#' + dados.cor_primaria,
                'icons':[
                    {
                        'src': dados.caminho_favicon,
                        'sizes':'192x192',
                        'type':'image/png'
                    },
                    {
                        'src': dados.caminho_favicon,
                        'sizes':'512x512',
                        'type':'image/png'
                    },
                    {
                        'src': dados.caminho_favicon,
                        'sizes':'192x192',
                        'type':'image/png',
                        'purpose':'maskable'
                    },
                    {
                        'src': dados.caminho_favicon,
                        'sizes':'512x512',
                        'type':'image/png',
                        'purpose':'maskable'
                    }
                ],
                'start_url':'.',
                'display':'standalone',
                'background_color': '#' + dados.cor_primaria
            }

            const stringManifest = JSON.stringify(manifest);
            const blob = new Blob([stringManifest], {type: 'application/json'});
            const manifestURL = URL.createObjectURL(blob);
            document.querySelector('link[rel="manifest"]').setAttribute('href', manifestURL);

            var link = document.createElement('Link');
            link.rel = 'manifest';
            link.setAttribute('href', 'data:application/json;charset=8' + stringManifest)
        }
    }
}
</script>