import store from '@/shared/store'
export const validaAutenticacao = async () => {
    const autenticacao = await store._modules.root.context.dispatch('usuarios/carregaUsuarioLogado')
    return !(autenticacao && autenticacao.status === 'error')
};

export const gerarTokenDispositivo = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let tokenDispositivo = '';
    for (let i = 0; i < 32; i++) {
        tokenDispositivo += chars[Math.floor(Math.random() * chars.length)];
    }
    return tokenDispositivo;
};
