export const validaEmail = ((email) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {
        return true;
    } else {
        return false;
    }
})

export const abreviaString = (texto, limite, limitador = '...') => {
    if(texto){
        if (texto.length > limite) {
            return texto.substr(0, limite - limitador.length) + limitador;
        }
    }
    return texto;
};

export const saudacao = ( () => {
    let resp = ''
    let hr = new Date().getHours()
    if (hr >= 12 && hr < 18) {
        resp = "Boa tarde";
    } else if (hr >= 0 && hr < 12) {
        resp = "Bom dia";
    } else {
        resp = "Boa noite";
    }
    return  resp;
})

export const removeHtml = ((html) => {
    if(html){
        return html.replace(/<[^>]+>/g, '')
    }else{
        return ''
    }
})

export const converterParaMaiusculas = (input) => {
    if (typeof input !== 'string') {
        return input;
    }
    return input.split('').map(char => {
        if (char >= 'a' && char <= 'z') {
            return String.fromCharCode(char.charCodeAt(0) - 32);
        }
        return char;
    }).join('');
};

export const ocultaCaracteres = (input) => {
    if (typeof input !== 'string') {
        return input;
    }
    if (!input) {
        return '';
    } 
    if (input.length  <= 4) {
        return input;
    }
    const visibleChars = 4;
    return input.substring(0, visibleChars) + '*'.repeat(input.length - visibleChars);
}

export const geraSlug = (texto) => {
    return texto
    .toLowerCase()
    .normalize('NFD') 
    .replace(/[\u0300-\u036f]/g, '') 
    .replace(/ /g, '_') 
    .replace(/[^a-z0-9_]/g, ''); 
}