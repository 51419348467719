import Vue from 'vue'
import axios from 'axios'
import autenticacao from './autenticacao'
import { baseURL} from './config'
import loading from '@/shared/plugins/loading'
import { formataUrlRedirecionamento } from '@/shared/common/functions/roteamento'
import { salvaMensagem } from '@/shared/common/functions/mensagens'
import '@/shared/plugins/recaptcha'

const token = autenticacao.pegaToken()
const $api = {

    recaptcha: false,
    
    headers: {
        'x-token-auth': token || '',
        'host-origin': window.location.host,
        'Content-Type': 'multipart/form-data'
    },

    async axios(
        url, 
        params = null, 
        method = 'get', 
        _baseURL = baseURL,
        headers = this.headers
    ){
        const _axios = axios.create({
            baseURL: _baseURL,
            headers: headers
        })
        _axios.interceptors.response.use((response) => {
            return response.data
        })
        if(params){
            return await _axios[method](url, params)
        }else{
            return await _axios[method](url)
        }
    },

    async request(
        url, 
        params = {}, 
        load = true, 
        method = 'get',
        contentType = 'multipart/form-data'
    ){
        if(load){
            loading.show()
        }
        let retorno = {}
        if(this.recaptcha){
            if (await Vue.prototype.$recaptchaLoaded()) {
                Vue.prototype.$recaptchaInstance.showBadge()
            }
            const recaptcha = await Vue.prototype.$recaptcha('login')
            if(params.data){
                Object.assign(params.data, {
                    'g-recaptcha-response': recaptcha
                })
            }else{
                Object.assign(params, {
                    'g-recaptcha-response': recaptcha
                })
            }
        }
        try {
            retorno = await this.axios(
                url, 
                params, 
                method,
                baseURL,
                {
                    'x-token-auth': autenticacao.pegaToken() || '',
                    'host-origin': window.location.host,
                    'Content-Type': contentType,
                }
            )
        } catch (error) {
            if(error.response){
                retorno = error.response.data
            }else if (error.request) {
                retorno = error.request
            } else {
                retorno = error
            }
        }
        if(load){
            loading.hide()
        }
        this.recaptcha = false
        this.redirect(retorno)
        return retorno
    },

    async get(url, params = {}, load = true) {
        return await this.request(url, {params: params}, load, 'get')
    },

    async post(url, params = {}, load = true, contentType = 'multipart/form-data') {
        return await this.request(url, params, load, 'post', contentType)
    },

    async put(url, params = {}, load = true) {
        return await this.request(url, params, load, 'put')
    },

    async delete(url, params = {}, load = true) {
        return await this.request(url, params, load, 'delete')
    },

    async cGet(url, params = {}){
        return await this.get(url, params, false)
    },

    async cPost(url, params = {}){
        return await this.post(url, params, false)
    },

    async cPut(url, params = {}){
        return await this.put(url, params, false)
    },

    async cDelete(url, params = {}){
        return await this.delete(url, params, false)
    },

    redirect(res){
        if(res.code && res.redirect){
            if([402, 403].includes(res.code)){
                loading.show()
                const redirect = formataUrlRedirecionamento(res.redirect)
                let url = redirect
                //se redirecionado para meu_plano e existe mensagem, passa a mensagem para o componente via query para exibir a mensagem no <b-alert>
                if(res.redirect.action == 'meu_plano' && res.message){
                    url = url + '?mensagem=' + res.message
                }
                else if(res.message){
                    salvaMensagem(res.message, 'Atenção', 'error')
                }
                window.location.href = url
            }
        }
    }
}
Vue.prototype.$api = $api
export default $api
