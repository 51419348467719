const routes = [
    {
        path: '/login',
        component: () => {
            const parametros = new URLSearchParams(window.location.search)
            const autenticao = parametros.get('autenticacao')
            if(autenticao){
                return import('@/domains/shared/configuracoes/usuarios/views/LoginToken')
            }else{
                return import('@/domains/shared/configuracoes/usuarios/views/Login')
            }
        },
        meta: { 
            public: true 
        },
        alias: [
            '/usuarios/login', 
            '/contabilidade/login'
        ],
    },
    
    {
        path: '/login_token',
        component: () => import('@/domains/shared/configuracoes/usuarios/views/LoginToken'),
        meta: { 
            public: true 
        },
        alias: [
            '/usuarios/login_token', 
        ],
    },
    {
        path: '/cadastro',
        component: () => import('@/domains/shared/configuracoes/usuarios/views/Cadastro'),
        meta: { public: true }
    },
    {
        path: '/cadastrar_senha/:hash?',
        meta: {
            public: true
        },
        component: () => import('@/domains/shared/configuracoes/usuarios/views/CadastrarSenha')
    },
    {
        path: '/usuarios/codigo_autenticacao',
        meta: {
            fullScreen: true,
            menuDisabled: true,
            public: true
        },
        component: () => import('@/domains/shared/configuracoes/usuarios/views/CodigoAutenticacao'),
    },
    {
        path: '/usuarios/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        meta: {
            base: '/ideias'
        },
        children: [
            {
                path: '/questionario',
                alias: '/empresas/questionario',
                component: () => import('@/domains/shared/configuracoes/usuarios/views/Questionario'),
                meta: {
                    fullScreen: true,
                    // menuDisabled: true
                }
            },
            {
                path: '/usuarios/meus_dados',
                component: () => import('@/domains/shared/configuracoes/usuarios/views/MeusDados'),
            },
            {
                path: '/usuarios/dispositivos',
                component: () => import('@/domains/shared/configuracoes/usuarios/views/Dispositivos'),
            },
            {
                path: '/usuarios',
                alias: '/usuarios/index',
                component: () => import('@/domains/shared/configuracoes/usuarios/views/Listar'),
            },
            {
                path: '/usuarios/adicionar',
                component: () => import('@/domains/shared/configuracoes/usuarios/views/Adicionar'),
            },
            {
                path: '/usuarios/editar/:id',
                component: () => import('@/domains/shared/configuracoes/usuarios/views/Editar'),
            },
            {
                path: '/usuarios/visualizar/:id',
                component: () => import('@/domains/shared/configuracoes/usuarios/views/Visualizar'),
            },
            {
                path: '/usuarios/redefinir_senha',
                component: () => import('@/domains/shared/configuracoes/usuarios/views/RedefinirSenha'),
                meta: { 
                    fullScreen: true
                },
            },
        ]
    },
]

export default routes